.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

/* Same as .App-header */
.App-content {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.Vote-button {
  width: 83%;
  margin: 20px;
  padding: 1em;
  background-color: #00006b;
  border-radius: 40px;
  border: 3px solid white;
  outline: none;
  font-size: calc(6px + 2vmin);
  color: white;
}

.Vote-button:hover {
  border-color: #aaa;
  color: #aaa;
}

.Admin-panel-button {
  width: 250px;
  margin: 20px 10px;
  padding: 1em;
  background-color: #0000dc;
  border-radius: 15px;
  border: 3px solid lightgray;
  font-size: calc(6px + 2vmin);
  color: white;
}

.Admin-panel-button:hover {
  border-color: #aaa;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
